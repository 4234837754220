/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';

// Assets
import HASH from '../images/news/hash.svg';
import CALENDAR from '../images/news/calendar.svg';
import BANNER_IMG from '../images/news/background.png';

// Styles
import '../page-styles/news.scss';

/* -------------------------------------------------------------------------- */
/*                          All References Page                               */
/* -------------------------------------------------------------------------- */

function AllNewsPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Nouveautés';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dabs le développement des solutions techniques pour la construction. ';

  /* ********************************** HOOKS ********************************* */
  const [news, setNews] = useState([]);

  /* -------------------------------- CONST --------------------------------- */
  useEffect(() => {
    axios
      .get(
        // eslint-disable-next-line max-len
        'https://admin.luxor.com.tn/api/1.1/tables/news/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
      )
      .then(({ data: { data } }) => {
        setNews(data);
      })
      .catch((error) => error);
  }, []);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <BannerSection bannerImage={BANNER_IMG}>
        <h1>Nouveautés</h1>
      </BannerSection>
      <Visible lg xl xxl>
        <Container className="" fluid>
          <BeardCrumbs path="news" titleBreadcrumbs="Nouveautés" />
        </Container>
      </Visible>
      <Container className="all-news top">
        {news?.map((element) => (
          <Container className="single-news fluid" key={element.id}>
            <img
              src={`https://admin.luxor.com.tn${element.picture.data.thumbnail_url}`}
              alt={element.title}
            />
            <h1>{element.title}</h1>
            <p>{element.details}</p>
            <Container className="icons flex fluid">
              <img src={CALENDAR} alt="calendar-icon" />
              <h6>{element.date}</h6>
            </Container>
            <Container className="icons flex items-center justify-center fluid">
              <img src={HASH} alt="hashtag-icon" />
              <h6>{element.tags}</h6>
            </Container>
          </Container>
        ))}
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AllNewsPage;
